import { useState } from 'react';
import { getPriceWithCurrency, mapColorsFromProducts } from '../../../../utils/helpers';
import ColorSelect from '../../../../components/ColorSelect/ColorSelect';
import './ColorSelector.sass';

export default function ColorSelector({ order, specialOffer, onSubmit, offer }) {
  const sortedProducts = specialOffer.products.sort((a, b) => a.qty - b.qty);
  const [selectedProductId, setSelectedProductId] = useState(specialOffer.products[0].id);
  const [selectedProductColor, setSelectedProductColor] = useState({
    name: specialOffer.products[0].color,
    imageSrc: specialOffer.products[0].colorImage,
  });

  const [selectedProduct, setSelectedProduct] = useState(
    specialOffer.products.find((item) => item.id === selectedProductId)
  );

  function handleColorSelect(color) {
    const selectedProduct = specialOffer.products.find((item) => item.color === color.name);

    setSelectedProductColor(color);
    setSelectedProductId(selectedProduct.id);
    setSelectedProduct(selectedProduct);
  }

  function renderProductSelector() {
    return (
      <select
        className='special-offer__select'
        name='productSelect'
        value={selectedProductId}
        onChange={(e) => setSelectedProductId(e.target.value)}
      >
        {sortedProducts.map((item, index) => {
          if (item.color === selectedProductColor.name) {
            return (
              <option key={index} value={item.id}>
                {item.qty} {item.name} for {getPriceWithCurrency(item.price, offer?.locale, offer?.currency)}
                {item.qty > 1 && '/ea'}
              </option>
            );
          }

          return null;
        })}
      </select>
    );
  }

  return (
    <div className='special-offer'>
      <div className='special-offer__title'>Wait, {order.firstName || 'dear customer'}!</div>
      <div
        className='special-offer__subtitle'
        dangerouslySetInnerHTML={{ __html: specialOffer.headline }}
      ></div>
      {specialOffer.gallery.map((item, index) => (
        <img key={index} src={item.source} alt='special offer' className='special-offer__img' />
      ))}
      {specialOffer.products.length > 1 && (
        <ColorSelect
          colors={mapColorsFromProducts(specialOffer.products)}
          selectedColor={selectedProductColor}
          onChange={handleColorSelect}
        />
      )}
      {sortedProducts.length > 1 && renderProductSelector()}
      <div className='special-offer__buttons'>
        <button className='special-offer__buttons--add' onClick={() => onSubmit(selectedProduct)}>
          Yes! Add {selectedProduct.qty} {selectedProduct.name} to my order for{' '}
          {getPriceWithCurrency(selectedProduct.price, offer?.locale, offer?.currency)}
          {selectedProduct.qty > 1 && '/ea'}
        </button>
        <button className='special-offer__buttons--skip' onClick={() => onSubmit()}>
          No, I don't want it
        </button>
      </div>
    </div>
  );
}
