import styles from './GreenAccent.module.sass';
import extra from './img/extra.png';

export default function GreenAccent({ specialOffer, onSubmit }) {
	const sortedProducts = specialOffer.products.sort((a, b) => a.qty - b.qty);
	const selectedProduct = sortedProducts[0];

	return (
		<div className={styles.container}>
			<div className={styles.steps}>
				<div className={styles.step}>STEP 1: SELECT QUANTITY</div>
				<div className={styles.step}>STEP 2: SPECIAL OFFER</div>
				<div className={styles.step}>STEP 3: ORDER COMPLETE</div>
			</div>

			<div className={styles.headline} dangerouslySetInnerHTML={{ __html: specialOffer.headline }}></div>
			<div className={styles.subTitle}>
				**To Prevent Issues, DO NOT Press 'Back', Reload, or Close This Page**
			</div>
			<div className={styles.content}>
				<div className={styles.contentLeft}>
					<img src={extra} className={styles.desktopOnly} alt={specialOffer.name} />
				</div>
				<div className={styles.contentRight}>
					<h4>
						Add 1x Additional <b>Knee Sleeve</b> To Your Order <b>TODAY</b> for Just $24.95!
						<b>(Save $55 Instantly)</b>
					</h4>
					<img src={extra} className={styles.mobileOnly} alt={specialOffer.name} />

					<p>
						Was <s>$79.95</s> Now Just <b>$25!</b> That's <b>$55 OFF</b>!
					</p>
					<p>
						Plus we'll pay for the added shipping cost. This is a one time offer and will never be made again.
						Just click the "Yes, I Want This Deal" button below to upgrade your order.
					</p>
					<button className={styles.yesButton} onClick={() => onSubmit(selectedProduct)}>
						<i className='fa-solid fa-cart-shopping'></i> YES! I WANT THIS DEAL
					</button>
				</div>
			</div>
			<div className={styles.noButton} onClick={() => onSubmit()}>
				No Thanks, I don't want this deal.
			</div>
		</div>
	);
}
